.songs-section{
   
    margin: 15px 10px;
    

  
    // box-shadow: 0px 3px 6px #272D3B33;
    border-radius: 30px;
    a{
      text-decoration: none;
    }
    .title{
        font-size: 22px;
        font-weight: bold;
        color: #ababab;
        margin: .5px 10px ;
        margin-bottom: 5px;
    
    }
    .grid-container {
       
     
        overflow: auto;
        white-space: nowrap
      
      }
      .video{
        width: 40%;
        margin:0px 5%;
        margin-bottom: 20px;
        max-height: 400px;
        text-align: center;
        display: inline-block;
        video{
            height: 150px;
            width: 100%;
            object-fit: cover;
        }
        .song-name{
            color: #ff6600;
            font-size: 15px;
            margin-top: 5px;
        }
        .artist-name{
            color: gray;
            font-size: 16px;
            
        }
      
      }
      .grid-container::-webkit-scrollbar {
        display: none;
      }

}