//player page
.player-page{
    padding-top: 70px;
    padding-bottom: 20px;
    min-height: calc( 100vh - 150px );
    .more-songs{
        margin-top: 20px;
        .more-title{
            font-size: 18px;  
            margin: 10px 20px;
            font-weight: bold;
            color: #7c7c7c;
           }
    }
    
    .player-with-image{
        position: relative;
        width: 100vw;
         .overlayer{
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: #00000050;
            z-index: 1;
         }
        img{
            width: 100%;
        
        }
        .player{
            position: absolute;
            bottom: 20px;
            width: 100vw;
            margin: auto;
            text-align: center;
            z-index: 3;
            .song-info{
                font-size: 20px;
                font-weight: 500;
                color: #fff;
            }

        }
        // --plyr-badge-background:red !important;
    }
    .plyr__controls{
        background-color:#ffffff00!important;
        --plyr-color-main:#ff6428 !important;
        
        // --plyr-video-controls-background: none !important;
        // --plyr-tab-focus-color:nne!important;
        // --plyr-control-toggle-checked-background:none!important;
        // --plyr-audio-control-background-hover:none!important;
        --plyr-audio-control-color:#ff6428;
        .plyr__controls__item:first-child{
               border-radius: 50%;
            //    position: absolute;
            //    bottom: 78px;
            //    left:calc(50vw - 26px ) ;
            margin: auto;
               padding: 20px;
            
               border:2px  solid  #ff6428;

               
        }
      
    }
    .traks-num{
        width: 100vw;
        text-align: center;
        margin-top: 10px;
        margin-bottom: -10px;
        font-size: 16px;
        color: gray;
    }
 .icons-actions{
    .icon{
        color:#4a5464;
        font-size: 16px;
        margin: 10px ;
        display: inline-block;
        text-align: center;
        cursor: pointer;
        .tone-type{
            color: #ff781e;

        }
    }
}

}

//player in playlist
.player-box{
    height: 200px;
    background-color: #e3e3e3 !important;
    .song-details{
       text-align: center;
       padding: 10px 0;
      
        .song-name{
            color: #ff6600;
            font-size: 20px;
          
        }
        .artist-name{
            color: gray;
            
        }
       
    }
    .icon{
        color:#4a5464;
        font-size: 16px;
        margin: 10px ;
        display: inline-block;
        text-align: center;
        cursor: pointer;
        .tone-type{
            color: #ff6428;;

        }
    }
    .plyr__controls{
        background-color: #e3e3e3 !important;
        
        --plyr-color-main: #ff6428;
    }
}
