.top-tones{
    max-width: 100vw;
padding-top: 65px;

.body{
    min-height: calc(100vh - 191px);
    padding-bottom: 50px;
    .top-section{
        position: relative;
        .overlayer{
            position: absolute;
            bottom: 0;
            background-image: linear-gradient(to bottom, rgba(255,0,0,0), #000);
            width: 100%;
            height: 90px;
            .text-container{
                position: relative;


            }
        }
        .text{
            position: absolute;
            top: 60px;
            width: 100%;
            text-align: center;
            display: inline-block;
            color: #fff;
            font-weight: bold;
           
        }
        .top-img{
            width: 100%;
            // height: 230px;
            border-radius: 10px;
            vertical-align: middle;
            
            }

    }
    .grid{
        padding: 15px;
        display: grid;
        grid-template-columns: auto auto ;
        gap: 30px;
        button{
            padding: 10px 0;
            border: none;
            border-radius: 20px;
        }
        .gray{
            background-color: #e3e3e3;
        }
        .orange{
            background-color: #ff6428;
            color: #fff;
           
        }
      
    }
 
}
}
.song-line{
    width: 90%;
    margin:10px auto;
    position: relative;
 

    .song-details{
        display: inline-block;
      
      
        .song-name{
            color: #ff6600;
            font-size: 14px;
          
        }
        .artist-name{
            color: gray;
            font-size: 12px;
            
        }
    }
    img{
        width: 50px;
        height: 50px;
        vertical-align: middle;
        margin-inline-end: 10px;
        margin-top: -8px;
        border-radius: 5px;
        object-fit: cover;
    }
    .oposite{
        margin-top: 18px;
        z-index: 1;
        position: sticky;
    }
    .list{
        position: absolute;
       z-index: 10000;
        box-shadow: 0px 3px 6px #00000029;
       
        right: 0;
        top: 38px;
        background-color: #fff;
       
        .list-option{
            padding: 10px 20px;
            border-bottom: solid 1px #272D3B33;
            font-size: 14px;
            .icon{
                color: #ff6428;
                vertical-align: text-top;
                font-size: 20px;
            }
             
        }
    }
  }