.artist-page{
    padding-top: 100px;
    .Artist-info{
        
        .artistName{
        
            text-align: center;
            margin-top: 5px;
            font-size: 16px;
            font-weight: bold;
            color: #323232;
          
       
        }
        .img{
            display: block;
            text-align: center;
           
            img{
               width: 140px;
               height: 140px;
               border-radius: 8px;
               object-fit: cover;
            }
           
        }

    }
    h5{
        margin: 10px ;
        font-size: 20px;
        color: #ababab;
    }
    .song-line{
        width: 90%;
        margin:10px auto;
        position: relative;
     
    
        .song-details{
            display: inline-block;
        
          
            .song-name{
                color: #ff6600;
                font-size: 14px;
              
            }
            .artist-name{
                color: gray;
                font-size: 13px;
            }
        }
        img{
            width: 50px;
            height: 50px;
            vertical-align: middle;
            margin-inline-end: 10px;
            margin-top: -8px;
            object-fit: cover;
            border-radius: 5px;
        }
        .oposite{
            margin-top: 18px;
            z-index: 1;
            position: sticky;
        }
        .list{
            position: absolute;
           z-index: 10000;
            box-shadow: 0px 3px 6px #00000029;
           
            right: 0;
            top: 38px;
            background-color: #fff;
           
            .list-option{
                padding: 10px 20px;
                border-bottom: solid 1px #272D3B33;
                font-size: 14px;
                .icon{
                    color: #ff6428;
                    vertical-align: text-top;
                    font-size: 20px;
                }
                 
            }
        }
      }
      .grid-container {
       
     
        overflow: auto;
        white-space: nowrap
      
      }
      .video{
        width:calc(50% - 10px);
        margin:0px 5px;
        max-height: 400px;
        text-align: center;
        display: inline-block;
        video{
            height: 150px;
            width: 100%;
            object-fit: cover;
        }
        .song-name{
            color: #ff6600;
            font-size: 15px;
            margin-top: 5px;
        }
        .artist-name{
            color: gray;
            font-size: 16px;
            
        }
      
      }
      .grid-container::-webkit-scrollbar {
        display: none;
      }
.artists-section {
margin-top: -10px !important;
img{
    width: 60px !important;
    height: 60px !important;
}
}
}