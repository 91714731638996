//reusable
@import "styless/reusable/header" ;
@import "styless/reusable/song" ;
@import "styless/reusable/footer" ;
@import "styless/reusable/album" ;
//home
@import "styless/home/upperSection" ;
@import "styless/home/songSection" ;
@import "styless/home/artistsSection" ;
@import "styless/home/mostRecent" ;
@import "styless/home/genres" ;
@import "styless/home/monthlySection" ;
//profile
@import "styless/profile/profile" ;

//topTones
@import "styless/topTones/topTones" ;

//topTones
@import "styless/player" ;

//playlists
@import "styless/playlists" ;

//genrePage
@import "styless/genrePage" ;

//artist
@import "styless/Artist" ;

//videos page
@import "styless/videosPage" ;

body{
    // font-family: 'Times New Roman', serif;
    // font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    max-width:615px;
    margin:auto;
}
.oposite{
    float: right;
}
.home{
    padding-top: 75px;
}
a{
    color: #000;
}

