
.artists-section{
    overflow: auto;
    white-space: nowrap;
    padding: 10px;
    padding-top: 5px;
    position: relative;
  
    .artist{
        display: inline-block;
        text-align: center;
        margin: 10px;
        img{
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
            margin-top: -5px;
        }
    }
}
.artists-section::-webkit-scrollbar {
    display: none;
  }
  .home-title{
    font-size: 22px;
    font-weight: bold;
    color: #ababab;
   margin-left: 20px;

}