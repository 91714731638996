.videos-page{
    padding-top: 87px;
   
 
    .title{
        font-size: 24px;
        font-weight: 500;
        margin: 15px 10px;
        margin-top: -6px;
        display: block;
        padding: 15px 10px;

    }
    .videos-container{
        min-height: calc( 100vh - 70px );
        padding-bottom: 10px;
        padding-top: 5px;
    
        background-color: #f5f5f5;
        border-radius: 20px;
       
        .video{
            width:100%;
            margin: 5px 0;
            max-height: 400px;
            max-width: 100%;
            text-align: center;
            display: inline-block;
            background-color: #fff;
            padding: 10px  0;
            padding-top: 30px;
           
            
            video{
                margin: auto;
                height: 200px;
                width:100%;
                object-fit: cover;
                
            }
            .details{
                text-align: left;
                padding: 10px;
                position: relative;
                img{
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    vertical-align: middle;
                    margin-inline-end: 7px;
                    object-fit: cover;
                }
                .oposite{
                    float: right;
                    margin-top: 12px;
                }
            }
            .song-name{
                color: gray;
                font-size: 16px;
                display: inline-block;
                
            }
            .artist-name{
                color: gray;
                font-size: 16px;
                display: inline-block;
                
            }
          
          }
        
    }
    .search{
        width: 80vw !important;
       margin-left: 5vw;
       margin-bottom: 20px;
     
    }
    .list{
        position: absolute;
       z-index: 10000;
        box-shadow: 0px 3px 6px #00000029;
       
        right: 30px;
        top: -12px;
        background-color: #fff;
       
        .list-option{
            padding: 10px 20px;
            border-bottom: solid 1px #272D3B33;
            font-size: 14px;
            .icon{
                color: #ff6428;
                vertical-align: text-top;
                font-size: 20px;
            }
             
        }
    }
}