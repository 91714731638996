.profile{
    max-width: 100vw;
    padding-top: 80px;
    .upper-section{
        margin: 10px 0 25px 0;
        .title{
            font-size: 28px;
            margin-top: -12px;
            font-weight: bold;
            display: block;
            color: #c7c7c7;
        
        }
        .blak{
           color: #000;
           font-weight: bold;
           font-size: 28px;
           margin-top: -15px;
        }
    }
   
    h2{
        text-align: center;
        color:  #ff7743;;
        margin-top: 30px;
        font-size: 20px !important;
    
    }
    .assigned-sections{
        min-height: calc(100vh - 318px);
       
        .oposite{
            color: #a39c99 !important;
        }
        .assigned{
            padding:10px 20px;
            .title{
                font-size: 23px;

                .icon{
                    vertical-align: middle;
                    color:#ff6428; ;
                }
            }
            .song{
                margin-inline-start: 24px;
                width: auto;
                color: gray;
                width: calc(100% - 24px);
                text-align: start;
                .icon{
                    vertical-align: middle;
                    color:#ff6428; ;
                }
              
            }
            .numbers{
                text-align: center !important;
                margin-top: 10px;
                .icon{
                    color: #a39c99;
                }
                .add{
                    margin: 10px 0;
                    color:#ff6428; ;
                    .icon{
                        margin-inline-end: 5px;
                        vertical-align: middle;
                        color:#ff6428; ;
                    }
                }
            }
        
        }
    }
    .add-section{
        background-color: #fff;
        display: block;
        margin:20px auto;
        border: solid 1px #ff6428 ;
       padding: 10px 30px;
        margin-bottom: 50px;
    }
    .img{
        width: 75px;
        height: 75px;
        margin: auto;
        text-align: center;
        position: relative;
        padding-bottom: 20px;
        border: solid 1px #d5d5d5;
        margin-top: 15px;
        margin-bottom: -10px;
        img{  
            
            max-width: 75px;
            height: 75px;
           border-radius: 8px;
           
            display: block;
           
    }
    .text{
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #fff;
        color: #e67e22;; 
        padding-top: 5px;
        font-size: 14px;
      
        .icon{
            vertical-align: text-bottom;
        }
    }
}

}