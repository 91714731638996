.upper-section{
    padding: 0px  20px;
    padding-top: 2px;
    max-width: 100%;
    margin: 5px  0;
    .title{
        font-size: 26px;
      
        margin: 15px 0;
        margin-top: 10px;
        display: block;
        span{
            font-weight: bold;
        }

    }
   
}
.search{
    width: 90%;
    max-width: 100%;
    position: relative;
    input{
        width: 100%;
        max-width: 100%;
        padding: 10px 20px;
        border: solid 1px #d1caca;
        border-radius: 30px;
    }
    .search-icon{
       position: absolute;
       right: -27px;
       font-size: 20px;
       top: 8px;
       color: #ff6428;

    }
}
.home-page-border{
    border-radius: 20px;
    background-color: #f5f5f5;
}