.genre-page{
    
    .title{
        font-size: 22px;
        margin-bottom: 10px;
        text-align: center;
        font-weight: bold;
}
.img{
    object-fit: cover !important;
}
}