.header {
  // font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  padding: 10px 20px;

  max-width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: #fff;
  z-index: 100;
     max-width:615px;
    margin:auto;
  .logo {
    display: inline-block;
  }
  .text {
    display: inline-block;
    vertical-align: super;
    margin-inline-start: 10px;
    font-weight: 500;
    font-size: 14px;
  }
  .oposite {
    color: #ff6428;
    height: 100%;
    margin-top: 12px;
    position: relative;
    .icon {
      vertical-align: middle;
      vertical-align: -webkit-baseline-middle;
      font-size: 28px;
      margin-inline-start: 7px;
      color: #ff6428;
    }
  }
  .list {
    position: absolute;
    background-color: #000000d9;
    // width: 90vw;
    right: 0;
    color: #fff;
    div {
      padding: 5px 30px;
      min-width: 107px;
      &:hover {
        color: #ff6428;
        .icon {
          color: #ff6428;
        }
      }
      .icon {
        font-size: 18px;
        vertical-align: middle;
        margin: 0 3px;
        color: #fff;
      }
    }
  }
}
