.footer{
    border-top: solid .25px #ff6428;
    
  

    background-color:  #ff6428;;
    font-size: 24px;
    padding-bottom: 10px;
    .option{
        display: inline-block;
        width: 17.824vw;
        margin:0 1vw;
      
        border-radius: 50%;
        background-color: #ff6428;
        color: #fff;
        text-align: center;
        span{
            vertical-align: text-bottom;
            vertical-align: -webkit-baseline-middle;
            display: inline-block;
            margin-top: 13px;
        }
    }
}