.monthly-section{
    overflow: auto;
    white-space: nowrap;
    padding-bottom: 10px;
    margin: 0 10px;
    .month{
        display: inline-block;
        text-align: center;
        margin:  10px;
        width: calc( 100%  - 20px );
       
        img{
            max-width: 100%;
            min-height: 100px;
            border-radius: 8px;
        }
        .text{
            font-size: 10px;
            font-weight: 500;
        }
    }
}

.monthly-section::-webkit-scrollbar {
    display: none;
  }