.song{
    display: inline-block;
    width: 29.5vw;
    padding: 5px;
    text-align: center;
    position: relative;
    .overlayer{
        position: absolute;
        top: 43px;
        left: 5px;
        width: calc( 100% - 10px);
        height: 70px;
        background-image: linear-gradient(to bottom, rgba(255,0,0,0), #000);
        border-radius:0 0 10px 10px  ;
        .song-name-container{
            position: relative;
            width: 100%;
            height: 100%;
            .song-name{
                position: absolute;
                width: 100%;
                bottom: 10px;
                color: #fff;
                font-size: 10px;
                font-weight: bold;
                text-align: center;
            }

        }
    }
    img{
        height: 108px;
        width: 100%;
        max-width: 100%;
        border-radius: 10px;
        object-fit: cover;
        margin-bottom: -16px;
      
  
    }
 
    .artist-name{
        font-size: 13px;
        color: #707070;
        
    }
   p{
    margin-bottom: 0;
   }
}