.playlists{
 padding-top: 70px;
  .title{
    font-size: 28px;
    margin: 12px ;
    font-weight: bold;
    display: block;
    color: #c7c7c7;
  }
   .container{
    display: grid;
    padding: 20px 0;
    grid-template-columns: auto auto;
        .playlist{
            width: 80%;
           
            margin: auto;
            margin-bottom: 10px;
            text-align: center;
            font-size: 18px;
            color: #a1a1a1;
          
            img{
                width: 100%;
                height: 150px;
                border-radius: 8px;
                object-fit: cover;
            }
        }
   }
  
}
a{
                
    text-decoration: none;


}