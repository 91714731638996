.genres {
       
     
    overflow: auto;
    white-space: nowrap;
    margin: 10px;
  
    a{
        color: #000;
    }
    .title{
        font-size: 25px;
        font-weight: bold;
        color: #ababab;
        margin:10px  20px;
    }
    .gener{
     display: inline-block;
     width: calc(100%/3);
     text-align: center;
     .box{
        width: 90%;
     
       
        font-size: 26px;
       
        margin: auto;
        img{
            width: 100%;
            object-fit: cover;
            border-radius: 7px;
        }
      

     }
     .text{
        font-size: 13px;
        color: #a1a1a1;
        // margin-top: 5px;
     }
    }
  
  }
  .gener-religion{
    // width: 50% !important;
    .text{
        font-size: 18px !important;

    }  
  }
 
  .genres::-webkit-scrollbar {
    display: none;
  }