.albums{
    overflow: auto;
    white-space: nowrap;
    margin: 10px ;
    padding-bottom: 10px;
    .album{
        // background: #fff;
        border-radius: 8px;
        // box-shadow: 0 2px 2px 0 rgb(0 0 0 / 16%), 0 0 0 1px rgb(0 0 0 / 8%);
        display: inline-block;
        width: calc(100vw / 2  - 20px);
        font-size: 13px;
        margin: 0px 7px ;
        margin-bottom: 10px 5px;
        text-align: center;
        color: #202124;
        padding-bottom: 5px;
        &:hover{
            width: auto;
            .album-name{ max-width: auto;
                overflow: visible;
              
               text-overflow: visible;
            }
        }
        img{
            width: 100%;
            height: 100px;
            object-fit: cover;
            border-radius: 8px 8px 0 0;;
        }
        .album-name{
          margin: 5px 0;
           direction:rtl;
          overflow: hidden;
          max-width: 98%;
         white-space: nowrap;
         text-overflow: ellipsis;
        }
        .date{
            margin: 5px 0;
            color: #70757a;
        }
        }
}
.albums::-webkit-scrollbar {
    display: none;
  }
