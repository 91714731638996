.most-recent{

  padding: 5px 0 !important;
  margin: 0 !important;
  .mostrecent-song{
    display: inline-block;
    width:100vw !important;
    padding: 5px;
  text-align: center;
  max-height: 50vh;
    img{
       
        width: 95% !important;
        max-height: 50vh;
        border-radius: 10px;
        object-fit: cover;
        margin-left: -10px;
  
    }
    .song-name{
        color: #ff6600;
        font-size: 22px;
        margin-top: 5px;
    }
    .artist-name{
        color: gray;
        
    }
}
.swiper-pagination-bullet-active{
  background-color: #ff6600;
}
}